
import { Component, Vue } from 'vue-property-decorator'
import { Page } from '../../types/common'
import { InfoPage } from '../../types/role'

  @Component
export default class Role extends Vue {
    private tableData = {
      loading: false,
      tr: [
        {
          label: '角色名称',
          prop: 'roleName',
          minWidth: '100',
          showOverflowTooltip: true
        },
        {
          label: '角色说明',
          prop: 'description',
          minWidth: '100',
          showOverflowTooltip: true
        },
        {
          label: '创建人',
          prop: 'createUserName',
          minWidth: '100',
          showOverflowTooltip: true
        },
        {
          label: '创建时间',
          prop: 'createTime',
          minWidth: '150',
          showOverflowTooltip: true
        }
      ],
      data: [{}]
    }

    private total = 0
    private page = 1
    private size = 10

    created () {
      this.getData()
    }

    getData () {
      this.tableData.loading = true
      this.$axios.get<Page<InfoPage>>(this.$apis.role.rolePage, {
        page: this.page,
        size: this.size
      }).then((res) => {
        this.tableData.loading = false
        this.total = res.total || 0
        this.tableData.data = res.list || []
      }).catch(() => {
        this.tableData.loading = false
      })
    }

    onAdd () {
      this.$router.push({
        name: 'roleAdd',
        params: {
          orderNum: String(this.total + 1)
        }
      })
    }

    onUpdate (id: string) {
      this.$router.push({
        name: 'roleUpdate',
        params: {
          id: id
        }
      })
    }

    onDelete (id: string) {
      this.$confirm('确认删除吗, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$axios.post(this.$apis.role.roleDelete, {
            id: id
          })
            .then(() => {
              this.$message({
                message: '删除成功',
                type: 'success'
              })
              this.page = 1
              this.getData()
            })
        })
        .catch((err) => {
          console.log(err)
        })
    }
}
